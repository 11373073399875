import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  RadioCards,
} from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSliderInput } from 'components/common/form/slider';
import { t } from 'i18next';
import {
  Orientation,
  ORIENTATION_SEAM_ALT_AZ,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import { useEffect, useState } from 'react';

const LIMIT_LATITUDE_DEG = 90;
const LIMIT_LONGITUDE_DEG = 180;

export const SeamOrientation = (props: {
  ball: IBallChar;
  setBall: (ball: Partial<IBallChar>) => void;
  showRef: boolean;
}) => {
  const [options, setOptions] = useState<IOption[]>([
    { label: t('pd.two-seam'), value: Orientation.FF2 },
    { label: t('pd.four-seam'), value: Orientation.FF4 },
    { label: t('pd.custom-orientation'), value: Orientation.CUS },
  ]);

  const [refBall, _setRefBall] = useState<IBallChar>({ ...props.ball });

  const [latitude, setLatitude] = useState(props.ball.latitude_deg);
  const [longitude, setLongitude] = useState(props.ball.longitude_deg);

  useEffect(() => {
    props.setBall({
      latitude_deg: latitude,
      longitude_deg: longitude,
    });
  }, [latitude, longitude]);

  return (
    <ErrorBoundary componentName="PDSeamOrientation">
      <Heading size={RADIX.HEADING.SIZE.SM}>{t('pd.seam-orientation')}</Heading>

      <RadioCards.Root
        size="1"
        gap={RADIX.FLEX.GAP.SM}
        defaultValue={props.ball.orientation}
        onValueChange={(value) => {
          const definition = ORIENTATION_SEAM_ALT_AZ[value];

          if (!definition) {
            props.setBall({
              orientation: value as Orientation,
            });

            return;
          }

          setLatitude(definition.latitude_deg);
          setLongitude(definition.longitude_deg);

          props.setBall({
            orientation: value as Orientation,

            latitude_deg: definition.latitude_deg,
            longitude_deg: definition.longitude_deg,
          });
        }}
      >
        {options.map((o, i) => (
          <RadioCards.Item key={i} value={o.value}>
            {o.label}
            {props.showRef && refBall.orientation === o.value ? '*' : ''}
          </RadioCards.Item>
        ))}
      </RadioCards.Root>

      {props.ball.orientation === Orientation.CUS && (
        <Grid columns="2" gap={RADIX.FLEX.GAP.SM}>
          <Flex gap={RADIX.FLEX.GAP.SM} justify="center">
            <Box>
              <IconButton
                color={RADIX.COLOR.NEUTRAL}
                variant="soft"
                disabled={latitude <= -LIMIT_LATITUDE_DEG}
                onClick={() => {
                  setLatitude(latitude - 1);
                }}
              >
                <MinusIcon />
              </IconButton>
            </Box>
            <Box flexGrow="1">
              <Button
                color={RADIX.COLOR.NEUTRAL}
                variant="soft"
                className="btn-block"
                onClick={() => {
                  setLatitude(props.showRef ? refBall.latitude_deg : 0);
                }}
              >
                {t('pd.latitude')}: {props.ball.latitude_deg.toFixed(0)}
              </Button>
            </Box>
            <Box>
              <IconButton
                color={RADIX.COLOR.NEUTRAL}
                variant="soft"
                disabled={latitude >= LIMIT_LATITUDE_DEG}
                onClick={() => {
                  setLatitude(latitude + 1);
                }}
              >
                <PlusIcon />
              </IconButton>
            </Box>
          </Flex>

          <Flex gap={RADIX.FLEX.GAP.SM} justify="center">
            <Box>
              <IconButton
                color={RADIX.COLOR.NEUTRAL}
                variant="soft"
                disabled={longitude <= -LIMIT_LONGITUDE_DEG}
                onClick={() => {
                  setLongitude(longitude - 1);
                }}
              >
                <MinusIcon />
              </IconButton>
            </Box>
            <Box flexGrow="1">
              <Button
                color={RADIX.COLOR.NEUTRAL}
                variant="soft"
                className="btn-block"
                onClick={() => {
                  setLongitude(props.showRef ? refBall.longitude_deg : 0);
                }}
              >
                {t('pd.longitude')}: {props.ball.longitude_deg.toFixed(0)}
              </Button>
            </Box>
            <Box>
              <IconButton
                color={RADIX.COLOR.NEUTRAL}
                variant="soft"
                disabled={longitude >= LIMIT_LONGITUDE_DEG}
                onClick={() => {
                  setLongitude(longitude + 1);
                }}
              >
                <PlusIcon />
              </IconButton>
            </Box>
          </Flex>

          <Box p="2">
            <CommonSliderInput
              id="PDSeamOrientationLatitude"
              min={-LIMIT_LATITUDE_DEG}
              max={LIMIT_LATITUDE_DEG}
              step={1}
              value={latitude}
              onChange={(v) => {
                setLatitude(v);
              }}
              hint_md={
                props.showRef
                  ? t('pd.current-x', {
                      x: refBall.latitude_deg.toFixed(0),
                    }).toString()
                  : undefined
              }
            />
          </Box>
          <Box p="2">
            <CommonSliderInput
              id="PDSeamOrientationLongitude"
              min={-LIMIT_LONGITUDE_DEG}
              max={LIMIT_LONGITUDE_DEG}
              step={1}
              value={longitude}
              onChange={(v) => {
                setLongitude(v);
              }}
              hint_md={
                props.showRef
                  ? t('pd.current-x', {
                      x: refBall.longitude_deg.toFixed(0),
                    }).toString()
                  : undefined
              }
            />
          </Box>
        </Grid>
      )}
    </ErrorBoundary>
  );
};
