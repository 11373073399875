import { Flex, Skeleton } from '@radix-ui/themes';
import { RouteHelper } from 'classes/helpers/route.helper';
import { SectionsHelper } from 'classes/helpers/sections.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { AdminPortal } from 'components/sections/admin-portal';
import { FeatureDemo } from 'components/sections/feature-demo';
import { SectionHeader } from 'components/sections/header';
import { HitterLibrary } from 'components/sections/hitter-library';
import { Home } from 'components/sections/home';
import { MachineCalibration } from 'components/sections/machine-calibration';
import { MlbStatsBrowse } from 'components/sections/mlb-stats-browse';
import { PitchDesign } from 'components/sections/pitch-design';
import { PitchList } from 'components/sections/pitch-list';
import { PitchLists } from 'components/sections/pitch-lists';
import { PitchUploader } from 'components/sections/pitch-uploader';
import { QuickSession } from 'components/sections/quick-session';
import { SessionHistory } from 'components/sections/session-history';
import { VideoLibrary } from 'components/sections/video-library';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import { IHittersContext } from 'contexts/hitters.context';
import { IMachineContext } from 'contexts/machine.context';
import {
  MlbBrowseContext,
  MlbBrowseProvider,
} from 'contexts/mlb-browse.context';
import {
  MatchingShotsContext,
  MatchingShotsProvider,
} from 'contexts/pitch-lists/matching-shots.context';
import { IPitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { ISectionsContext } from 'contexts/sections.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { SectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const PseudoSection = (props: { header: string; content: React.ReactNode }) => (
  <Flex p={RADIX.FLEX.PAD.SM} direction="column" gap={RADIX.FLEX.GAP.SM}>
    <SectionHeader header={props.header} />
    <CommonCallout color="gray" content={props.content} />
  </Flex>
);

/** shown when attempting to access an invalid route */
const SECTION_NOT_FOUND = (
  <PseudoSection
    header={t('main.not-found')}
    content={
      <p>Please check the URL or your access permissions and try again.</p>
    }
  />
);

/** shown when attempting to access a section that is not allowed for the user */
const SECTION_UNAVAILABLE = (
  <PseudoSection
    header={t('main.unavailable')}
    content={
      <>
        <p>This section is currently unavailable.</p>
        <p>Please try again later.</p>
      </>
    }
  />
);

/** shown when attempting to access a section that is not allowed during a game/in offline mode */
const SECTION_GAME_IN_PROGRESS = (
  <PseudoSection
    header={t('main.game-in-progress')}
    content={
      <>
        <p>There is a game in progress for your team.</p>
        <p>
          As a result, certain features of the application will be unavailable
          or restricted until after the game.
        </p>
      </>
    }
  />
);

interface IProps {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  globalCx: IGlobalContext;
  hittersCx: IHittersContext;
  listCx: IPitchListsContext;
  machineCx: IMachineContext;
  designCx: IPitchDesignContext;
  sectionsCx: ISectionsContext;
}

interface IState {}

export class SectionRouter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.props.authCx.checkTerms();
  }

  render() {
    return (
      <ErrorBoundary componentName="SectionRouter">
        <MlbBrowseProvider
          cookiesCx={this.props.cookiesCx}
          authCx={this.props.authCx}
        >
          <SessionEventsContext.Consumer>
            {(sessionsCx) => {
              const HOME = (
                <Home
                  authCx={this.props.authCx}
                  sectionsCx={this.props.sectionsCx}
                  sessionsCx={sessionsCx}
                />
              );

              const DEFAULT_PD = (
                <MatchingShotsProvider
                  authCx={this.props.authCx}
                  sectionsCx={this.props.sectionsCx}
                  machineCx={this.props.machineCx}
                >
                  <MatchingShotsContext.Consumer>
                    {(matchingCx) => {
                      if (!this.props.designCx.reference) {
                        this.props.designCx.setReference(
                          this.props.machineCx.getDefaultPitch()
                        );
                      }

                      if (!this.props.designCx.ball) {
                        // wait for ball to be built from the reference pitch
                        return <Skeleton />;
                      }

                      return (
                        <PitchDesign
                          cookiesCx={this.props.cookiesCx}
                          authCx={this.props.authCx}
                          machineCx={this.props.machineCx}
                          designCx={this.props.designCx}
                          sectionsCx={this.props.sectionsCx}
                          matchingCx={matchingCx}
                        />
                      );
                    }}
                  </MatchingShotsContext.Consumer>
                </MatchingShotsProvider>
              );

              return (
                <Routes>
                  <Route index element={HOME} />

                  <Route
                    path={RouteHelper.section(SectionName.Home)}
                    element={HOME}
                  />

                  {this.props.authCx.current.quick_session && (
                    <Route
                      path={RouteHelper.section(SectionName.QuickSession)}
                      element={
                        <MatchingShotsProvider
                          authCx={this.props.authCx}
                          sectionsCx={this.props.sectionsCx}
                          machineCx={this.props.machineCx}
                        >
                          <MatchingShotsContext.Consumer>
                            {(matchingCx) => (
                              <QuickSession
                                authCx={this.props.authCx}
                                machineCx={this.props.machineCx}
                                sectionsCx={this.props.sectionsCx}
                                matchingCx={matchingCx}
                              />
                            )}
                          </MatchingShotsContext.Consumer>
                        </MatchingShotsProvider>
                      }
                    />
                  )}

                  {/* existing pitch */}
                  <Route
                    path={`${RouteHelper.section(SectionName.PitchDesign)}/:id`}
                    element={
                      this.props.designCx.reference ? (
                        <MatchingShotsProvider
                          authCx={this.props.authCx}
                          sectionsCx={this.props.sectionsCx}
                          machineCx={this.props.machineCx}
                        >
                          <MatchingShotsContext.Consumer>
                            {(matchingCx) => {
                              if (!this.props.designCx.reference) {
                                return;
                              }

                              if (!this.props.designCx.ball) {
                                // wait for ball to be built from the reference pitch
                                return <Skeleton />;
                              }

                              return (
                                <PitchDesign
                                  cookiesCx={this.props.cookiesCx}
                                  authCx={this.props.authCx}
                                  machineCx={this.props.machineCx}
                                  designCx={this.props.designCx}
                                  sectionsCx={this.props.sectionsCx}
                                  matchingCx={matchingCx}
                                />
                              );
                            }}
                          </MatchingShotsContext.Consumer>
                        </MatchingShotsProvider>
                      ) : (
                        DEFAULT_PD
                      )
                    }
                  />

                  {/* new pitch */}
                  <Route
                    path={RouteHelper.section(SectionName.PitchDesign)}
                    element={DEFAULT_PD}
                  />

                  <Route
                    path={RouteHelper.section(SectionName.BulkPitchUpload)}
                    element={
                      <VideosContext.Consumer>
                        {(videosCx) => (
                          <PitchUploader
                            cookiesCx={this.props.cookiesCx}
                            authCx={this.props.authCx}
                            machineCx={this.props.machineCx}
                            sectionsCx={this.props.sectionsCx}
                            videosCx={videosCx}
                          />
                        )}
                      </VideosContext.Consumer>
                    }
                  />

                  {this.props.authCx.current.mlb_stats_api && (
                    <Route
                      path={RouteHelper.section(SectionName.MlbStatsBrowse)}
                      element={
                        <MlbBrowseContext.Consumer>
                          {(browseCx) => (
                            <VideosContext.Consumer>
                              {(videosCx) => (
                                <MatchingShotsProvider
                                  authCx={this.props.authCx}
                                  sectionsCx={this.props.sectionsCx}
                                  machineCx={this.props.machineCx}
                                >
                                  <MatchingShotsContext.Consumer>
                                    {(matchingCx) => (
                                      <AimingProvider
                                        machineCx={this.props.machineCx}
                                        videosCx={videosCx}
                                      >
                                        <AimingContext.Consumer>
                                          {(aimingCx) => (
                                            <MlbStatsBrowse
                                              globalCx={this.props.globalCx}
                                              cookiesCx={this.props.cookiesCx}
                                              authCx={this.props.authCx}
                                              machineCx={this.props.machineCx}
                                              designCx={this.props.designCx}
                                              sectionsCx={this.props.sectionsCx}
                                              listsCx={this.props.listCx}
                                              browseCx={browseCx}
                                              videosCx={videosCx}
                                              matchingCx={matchingCx}
                                              aimingCx={aimingCx}
                                            />
                                          )}
                                        </AimingContext.Consumer>
                                      </AimingProvider>
                                    )}
                                  </MatchingShotsContext.Consumer>
                                </MatchingShotsProvider>
                              )}
                            </VideosContext.Consumer>
                          )}
                        </MlbBrowseContext.Consumer>
                      }
                    />
                  )}

                  <Route
                    path={RouteHelper.section(SectionName.HitterLibrary)}
                    element={
                      <HitterLibrary
                        globalCx={this.props.globalCx}
                        cookiesCx={this.props.cookiesCx}
                        hittersCx={this.props.hittersCx}
                      />
                    }
                  />

                  <Route
                    path={RouteHelper.section(SectionName.VideoLibrary)}
                    element={
                      <VideosContext.Consumer>
                        {(videosCx) => (
                          <VideoLibrary
                            globalCx={this.props.globalCx}
                            cookiesCx={this.props.cookiesCx}
                            authCx={this.props.authCx}
                            machineCx={this.props.machineCx}
                            sectionsCx={this.props.sectionsCx}
                            videosCx={videosCx}
                          />
                        )}
                      </VideosContext.Consumer>
                    }
                  />

                  <Route
                    path={RouteHelper.section(SectionName.SessionHistory)}
                    element={<SessionHistory />}
                  />

                  <Route
                    path={RouteHelper.section(SectionName.PitchLists)}
                    element={
                      <MatchingShotsProvider
                        authCx={this.props.authCx}
                        sectionsCx={this.props.sectionsCx}
                        machineCx={this.props.machineCx}
                      >
                        <MatchingShotsContext.Consumer>
                          {(matchingCx) => (
                            <PitchLists
                              globalCx={this.props.globalCx}
                              cookiesCx={this.props.cookiesCx}
                              authCx={this.props.authCx}
                              listsCx={this.props.listCx}
                              sectionsCx={this.props.sectionsCx}
                              machineCx={this.props.machineCx}
                              matchingCx={matchingCx}
                            />
                          )}
                        </MatchingShotsContext.Consumer>
                      </MatchingShotsProvider>
                    }
                  />

                  <Route
                    path={`${RouteHelper.section(SectionName.Search)}`}
                    element={
                      <VideosContext.Consumer>
                        {(videosCx) => (
                          <MatchingShotsProvider
                            authCx={this.props.authCx}
                            sectionsCx={this.props.sectionsCx}
                            machineCx={this.props.machineCx}
                          >
                            <MatchingShotsContext.Consumer>
                              {(matchingCx) => (
                                <AimingProvider
                                  machineCx={this.props.machineCx}
                                  matchingCx={matchingCx}
                                  videosCx={videosCx}
                                >
                                  <AimingContext.Consumer>
                                    {(aimingCx) => (
                                      <PitchList
                                        globalCx={this.props.globalCx}
                                        cookiesCx={this.props.cookiesCx}
                                        authCx={this.props.authCx}
                                        hittersCx={this.props.hittersCx}
                                        listsCx={this.props.listCx}
                                        machineCx={this.props.machineCx}
                                        designCx={this.props.designCx}
                                        sectionsCx={this.props.sectionsCx}
                                        videosCx={videosCx}
                                        matchingCx={matchingCx}
                                        aimingCx={aimingCx}
                                      />
                                    )}
                                  </AimingContext.Consumer>
                                </AimingProvider>
                              )}
                            </MatchingShotsContext.Consumer>
                          </MatchingShotsProvider>
                        )}
                      </VideosContext.Consumer>
                    }
                  />

                  <Route
                    path={`${RouteHelper.section(SectionName.PitchList)}/:id`}
                    element={
                      <VideosContext.Consumer>
                        {(videosCx) => (
                          <MatchingShotsProvider
                            authCx={this.props.authCx}
                            sectionsCx={this.props.sectionsCx}
                            machineCx={this.props.machineCx}
                          >
                            <MatchingShotsContext.Consumer>
                              {(matchingCx) => (
                                <AimingProvider
                                  machineCx={this.props.machineCx}
                                  matchingCx={matchingCx}
                                  videosCx={videosCx}
                                >
                                  <AimingContext.Consumer>
                                    {(aimingCx) => (
                                      <PitchList
                                        globalCx={this.props.globalCx}
                                        cookiesCx={this.props.cookiesCx}
                                        authCx={this.props.authCx}
                                        hittersCx={this.props.hittersCx}
                                        listsCx={this.props.listCx}
                                        machineCx={this.props.machineCx}
                                        designCx={this.props.designCx}
                                        sectionsCx={this.props.sectionsCx}
                                        videosCx={videosCx}
                                        matchingCx={matchingCx}
                                        aimingCx={aimingCx}
                                      />
                                    )}
                                  </AimingContext.Consumer>
                                </AimingProvider>
                              )}
                            </MatchingShotsContext.Consumer>
                          </MatchingShotsProvider>
                        )}
                      </VideosContext.Consumer>
                    }
                  />

                  {SectionsHelper.isAnyAdmin(
                    this.props.authCx.current.role
                  ) && (
                    <Route
                      path={
                        // admin portal will handle further routing
                        RouteHelper.section(SectionName.AdminPortal) + '/*'
                      }
                      element={<AdminPortal />}
                    />
                  )}

                  <Route
                    path={RouteHelper.section(SectionName.MachineCalibration)}
                    element={
                      <MachineCalibration
                        cookiesCx={this.props.cookiesCx}
                        authCx={this.props.authCx}
                        machineCx={this.props.machineCx}
                        sectionsCx={this.props.sectionsCx}
                      />
                    }
                  />

                  {SectionsHelper.isSuperAdmin(
                    this.props.authCx.current.role
                  ) && (
                    <Route
                      path={RouteHelper.section(SectionName.FeatureDemo)}
                      element={<FeatureDemo cookiesCx={this.props.cookiesCx} />}
                    />
                  )}

                  <Route
                    path={RouteHelper.section(SectionName.FeatureDemo)}
                    element={<FeatureDemo cookiesCx={this.props.cookiesCx} />}
                  />

                  <Route
                    path={RouteHelper.section(SectionName.Unavailable)}
                    element={SECTION_UNAVAILABLE}
                  />

                  <Route
                    path={RouteHelper.section(SectionName.GameInProgress)}
                    element={SECTION_GAME_IN_PROGRESS}
                  />

                  <Route path="*" element={SECTION_NOT_FOUND} />
                </Routes>
              );
            }}
          </SessionEventsContext.Consumer>
        </MlbBrowseProvider>
      </ErrorBoundary>
    );
  }
}
