import { t } from 'i18next';
import { clamp } from 'lib_ts/classes/math.utilities';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';

export const BS_LIMITS = {
  SPEED_MPH: {
    MIN: 50,
    MAX: 110,
  },
  SPIN_RPM: {
    MIN: -3000,
    MAX: 3000,
  },
  BREAK_IN: {
    MIN: -30,
    MAX: 30,
  },
  GYRO_DEG: {
    MIN: -90,
    MAX: 90,
  },
  AXIS_DEG: {
    MIN: -180,
    MAX: 180,
  },
};

export class PitchDesignHelper {
  static HB_TOOLTIP_TEXT = 'Positive values correspond to movement towards 3B';
  static VB_TOOLTIP_TEXT = 'This value does not include gravity';

  static validateBallSpeed(value: number | undefined): boolean {
    if (value === undefined) {
      return false;
    }

    return (
      clamp(value, BS_LIMITS.SPEED_MPH.MIN, BS_LIMITS.SPEED_MPH.MAX) === value
    );
  }

  static validateGyroAngle(value: number | undefined): boolean {
    if (value === undefined) {
      return false;
    }

    return (
      clamp(value, BS_LIMITS.GYRO_DEG.MIN, BS_LIMITS.GYRO_DEG.MAX) === value
    );
  }

  static validateSpinAxis(value: number | undefined): boolean {
    if (value === undefined) {
      return false;
    }

    return (
      clamp(value, BS_LIMITS.AXIS_DEG.MIN, BS_LIMITS.AXIS_DEG.MAX) === value
    );
  }

  static validateSpin(value: number | undefined): boolean {
    if (value === undefined) {
      return false;
    }

    return (
      clamp(value, BS_LIMITS.SPIN_RPM.MIN, BS_LIMITS.SPIN_RPM.MAX) === value
    );
  }

  static validateBreak(value: number | undefined): boolean {
    if (value === undefined) {
      return false;
    }

    return (
      clamp(value, BS_LIMITS.BREAK_IN.MIN, BS_LIMITS.BREAK_IN.MAX) === value
    );
  }

  /** returns a list of error messages based on whether ball is valid */
  static getBallErrors = (
    ball: IBallChar,
    priority: BuildPriority | undefined
  ): string[] => {
    const output: string[] = [];

    if (!this.validateBallSpeed(ball.speed)) {
      output.push(
        t('pd.msg-speed-between-x-and-y-units', {
          x: BS_LIMITS.SPEED_MPH.MIN,
          y: BS_LIMITS.SPEED_MPH.MAX,
          units: 'MPH',
        })
      );
    }

    if (
      priority !== undefined &&
      [BuildPriority.Spins, BuildPriority.Breaks].includes(priority)
    ) {
      if (!this.validateSpin(ball.wnet)) {
        output.push(
          t('pd.msg-spin-between-x-rpm', {
            x: BS_LIMITS.SPIN_RPM.MAX,
          })
        );
      }
    }

    switch (priority) {
      case BuildPriority.Spins: {
        if (!this.validateGyroAngle(ball.gyro_angle)) {
          output.push(
            t('pd.msg-gyro-angle-between-x-y-degrees', {
              x: BS_LIMITS.GYRO_DEG.MIN,
              y: BS_LIMITS.GYRO_DEG.MAX,
            })
          );
        }

        if (!this.validateSpinAxis(ball.waxis)) {
          output.push(
            t('pd.msg-spin-axis-between-x-y-degrees', {
              x: BS_LIMITS.AXIS_DEG.MIN,
              y: BS_LIMITS.AXIS_DEG.MAX,
            })
          );
        }
        break;
      }

      case BuildPriority.Breaks: {
        if (!ball.breaks) {
          output.push('Breaks are not defined.');
          break;
        }

        const breaks = [
          { name: 'Horizontal break', value: ball.breaks.xInches },
          { name: 'Vertical break', value: ball.breaks.zInches },
        ];

        breaks.forEach((m) => {
          if (m.value > BS_LIMITS.BREAK_IN.MAX) {
            output.push(
              t('pd.msg-break-over-x-inches', {
                break: m.name,
                x: BS_LIMITS.BREAK_IN.MAX,
              })
            );
          } else if (m.value < BS_LIMITS.BREAK_IN.MIN) {
            output.push(
              t('pd.msg-break-under-x-inches', {
                break: m.name,
                x: BS_LIMITS.BREAK_IN.MIN,
              })
            );
          }
        });
        break;
      }

      default: {
        if (
          !this.validateSpin(ball.wx) ||
          !this.validateSpin(ball.wy) ||
          !this.validateSpin(ball.wz)
        ) {
          output.push(
            t('pd.msg-spin-between-x-rpm', {
              x: BS_LIMITS.SPIN_RPM.MAX,
            })
          );
        }
        break;
      }
    }

    return output;
  };
}
