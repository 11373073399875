import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import { Box, Flex, Separator } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import { CommonTooltip } from 'components/common/tooltip';
import { SidebarFooter } from 'components/main/sidebar/footer';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { SidebarPitchLists } from 'components/main/sidebar/pitch-lists';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { InboxContext } from 'contexts/inbox';
import { IMachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { ISectionsContext, SectionsContext } from 'contexts/sections.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { CookieKey, SidebarState } from 'enums/cookies.enums';
import { SectionName } from 'enums/route.enums';
import { nextSidebarState } from 'interfaces/cookies/i-app.cookie';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'Sidebar';

const BUILD_STAMP = env.getBuildStamp();

const SidebarSeparator = () => (
  <Box>
    <Separator mt="1" mb="1" size="4" />
  </Box>
);

const IMG_DEFS: { state: SidebarState; path: string }[] = [
  { state: SidebarState.full, path: 'brand/logo-white.svg' },
  { state: SidebarState.mini, path: 'brand/icon-white.svg' },
];

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  sectionsCx: ISectionsContext;
  machineCx: IMachineContext;
}

interface IState {}

export class Sidebar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.toggleSidebarState = this.toggleSidebarState.bind(this);
    this.renderAdminSections = this.renderAdminSections.bind(this);
    this.renderUserSections = this.renderUserSections.bind(this);
    this.renderPitchLists = this.renderPitchLists.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  private toggleSidebarState() {
    this.props.cookiesCx.setCookie(CookieKey.app, {
      sidebar_state: nextSidebarState(this.props.cookiesCx.app.sidebar_state),
    });
  }

  render() {
    const def =
      IMG_DEFS.find(
        (d) => d.state === this.props.cookiesCx.app.sidebar_state
      ) ?? IMG_DEFS[0];

    const hasAdminSections =
      this.props.sectionsCx.adminSections.filter((s) => !s.invisible).length >
      0;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex
          data-identifier="Sidebar"
          className={RADIX.VFLEX.WRAPPER}
          direction="column"
          gap={RADIX.FLEX.GAP.MAIN_MENU}
          // prevents content from resizing this thing
          overflowX="hidden"
          width={
            this.props.cookiesCx.app.sidebar_state === SidebarState.full
              ? '252px'
              : undefined
          }
          p={RADIX.FLEX.PAD.MAIN}
        >
          <Flex
            data-identifier="SidebarHeader"
            gap={RADIX.FLEX.GAP.SM}
            pl="4"
            pr="4"
          >
            <Box pt="1">
              <HamburgerMenuIcon
                className="cursor-pointer"
                onClick={this.toggleSidebarState}
                width="20px"
                height="20px"
              />
            </Box>

            {this.props.cookiesCx.app.sidebar_state === SidebarState.full && (
              <Box>
                <CommonTooltip
                  trigger={
                    <img
                      className="cursor-pointer select-none"
                      style={{
                        height: '32px',
                        width: 'auto',
                      }}
                      alt={def.path}
                      src={`${window.origin}/${def.path}`}
                      onClick={() =>
                        this.props.sectionsCx.tryChangeSection({
                          name: SectionName.Home,
                          trigger: 'sidebar logo',
                        })
                      }
                    />
                  }
                  text={`${env.identifier.toUpperCase()} ${BUILD_STAMP}`}
                />
              </Box>
            )}
          </Flex>

          <Box
            data-identifier="SidebarScrollable"
            flexGrow="1"
            overflowY="scroll"
            overflowX="hidden"
            className="scroll-hover"
          >
            <Box data-identifier="SidebarUserSections">
              <CommonNavMenu orientation="vertical">
                {this.renderUserSections()}
              </CommonNavMenu>
            </Box>

            {hasAdminSections && (
              <>
                <SidebarSeparator />

                <Box data-identifier="SidebarAdminSections">
                  <CommonNavMenu orientation="vertical">
                    {this.renderAdminSections()}
                  </CommonNavMenu>
                </Box>
              </>
            )}

            <SidebarSeparator />

            {this.renderPitchLists()}
          </Box>

          <SidebarSeparator />

          <Box className="SidebarFooter">{this.renderFooter()}</Box>
        </Flex>
      </ErrorBoundary>
    );
  }

  private renderUserSections() {
    const showLabels =
      this.props.cookiesCx.app.sidebar_state === SidebarState.full;

    return (
      <>
        {this.props.sectionsCx.userSections
          .filter((s) => !s.invisible)
          .map((s, i) => (
            <SidebarNavigationTrigger
              key={`user-section-${i}`}
              active={this.props.sectionsCx.active.name === s.value}
              label={s.label}
              icon={<s.icon />}
              showLabels={showLabels}
              onClick={() => {
                if (s.value === this.props.sectionsCx.active.name) {
                  // no need to move
                  return;
                }

                this.props.sectionsCx.tryChangeSection({
                  trigger: 'sidebar, section change',
                  name: s.value,
                });
              }}
            />
          ))}
      </>
    );
  }

  private renderAdminSections() {
    const showLabels =
      this.props.cookiesCx.app.sidebar_state === SidebarState.full;

    return this.props.sectionsCx.adminSections
      .filter((s) => !s.invisible)
      .map((s, i) => {
        return (
          <SidebarNavigationTrigger
            key={`admin-section-${i}`}
            label={s.label}
            icon={<s.icon />}
            showLabels={showLabels}
            active={this.props.sectionsCx.active.name === s.value}
            onClick={() => {
              this.props.sectionsCx.tryChangeSection({
                trigger: 'sidebar, section change',
                name: s.value,
              });
            }}
          />
        );
      });
  }

  private renderPitchLists() {
    return (
      <SectionsContext.Consumer>
        {(sectionsCx) => (
          <PitchListsContext.Consumer>
            {(listsCx) => (
              <SidebarPitchLists
                authCx={this.props.authCx}
                cookiesCx={this.props.cookiesCx}
                listsCx={listsCx}
                machineCx={this.props.machineCx}
                sectionsCx={sectionsCx}
              />
            )}
          </PitchListsContext.Consumer>
        )}
      </SectionsContext.Consumer>
    );
  }

  private renderFooter() {
    return (
      <SessionEventsContext.Consumer>
        {(sessionsCx) => (
          <InboxContext.Consumer>
            {(inboxCx) => (
              <SidebarFooter
                cookiesCx={this.props.cookiesCx}
                authCx={this.props.authCx}
                machineCx={this.props.machineCx}
                sessionsCx={sessionsCx}
                inboxCx={inboxCx}
              />
            )}
          </InboxContext.Consumer>
        )}
      </SessionEventsContext.Consumer>
    );
  }
}
