import { IMachineContext } from 'contexts/machine.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';

export interface IPitchDesignContext {
  // e.g. from a pitch list or MLB API
  reference?: IPitch;
  readonly setReference: (pitch: IPitch) => void;

  // for forms and editors
  ball?: IBallChar;
  readonly setBall: (ball: IBallChar) => void;
}

const DEFAULT: IPitchDesignContext = {
  setReference: () => console.debug('not init'),
  setBall: () => console.debug('not init'),
};

export const PitchDesignContext = createContext(DEFAULT);

interface IProps {
  machineCx: IMachineContext;
  listsCx: IPitchListsContext;

  children: ReactNode;
}

export const PitchDesignProvider: FC<IProps> = (props) => {
  // note: reference py may differ from machine plate distance if the pitch was originally created for a different machine
  const [_reference, _setReference] = useState(DEFAULT.reference);

  // ball py should always match machine plate distance
  const [_ball, _setBall] = useState(DEFAULT.ball);

  const state: IPitchDesignContext = {
    reference: _reference,
    setReference: (pitch) => {
      _setReference(pitch);
      _setBall(BallHelper.getCharsFromPitch(pitch));
    },

    ball: _ball,
    setBall: (ball) => {
      _setBall(ball);
    },
  };

  // keep ball py in sync with plate distance
  useEffect(() => {
    if (_ball) {
      _setBall({
        ..._ball,
        py: props.machineCx.machine.plate_distance,
      });
    }
  }, [props.machineCx.machine.plate_distance]);

  return (
    <PitchDesignContext.Provider value={state}>
      {props.children}
    </PitchDesignContext.Provider>
  );
};
