import { Box, Flex } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { Maintenance } from 'components/main/landing/forms/maintenance';
import { Login } from 'components/main/landing/login';
import { MachineResponseListener } from 'components/main/listeners/machine-response';
import { NotificationListener } from 'components/main/listeners/notification';
import { QueueListener } from 'components/main/listeners/queue';
import { Sidebar } from 'components/main/sidebar';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import {
  HittersContext,
  HittersProvider,
  IHittersContext,
} from 'contexts/hitters.context';
import { InboxContext } from 'contexts/inbox';
import {
  IMachineContext,
  MachineContext,
  MachineProvider,
} from 'contexts/machine.context';
import {
  MatchingShotsContext,
  MatchingShotsProvider,
} from 'contexts/pitch-lists/matching-shots.context';
import { PitchDesignProvider } from 'contexts/pitch-lists/pitch-design.context';
import {
  IPitchListsContext,
  PitchListsContext,
  PitchListsProvider,
} from 'contexts/pitch-lists/pitch-lists.context';
import {
  ISectionsContext,
  SectionsContext,
  SectionsProvider,
} from 'contexts/sections.context';
import {
  ISessionEventsContext,
  SessionEventsContext,
  SessionEventsProvider,
} from 'contexts/session-events.context';
import { VideosProvider } from 'contexts/videos/videos.context';
import React from 'react';
import { Content } from './content';

interface IProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
}

interface IState {}

const DEFAULT_STATE: IState = {};

export class Main extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.renderAuthorized = this.renderAuthorized.bind(this);
    this.renderUnauthorized = this.renderUnauthorized.bind(this);
    this.renderMain = this.renderMain.bind(this);

    this.provideMachine = this.provideMachine.bind(this);
    this.providePitch = this.providePitch.bind(this);
    this.providePitchLists = this.providePitchLists.bind(this);
    this.provideSections = this.provideSections.bind(this);
  }

  private renderAuthorized(): React.ReactNode {
    return (
      <VideosProvider authCx={this.props.authCx}>
        <HittersProvider authCx={this.props.authCx}>
          <HittersContext.Consumer>
            {(hittersCx) => (
              <SessionEventsProvider authCx={this.props.authCx}>
                <SessionEventsContext.Consumer>
                  {(sessionsCx) => this.provideMachine(hittersCx, sessionsCx)}
                </SessionEventsContext.Consumer>
              </SessionEventsProvider>
            )}
          </HittersContext.Consumer>
        </HittersProvider>
      </VideosProvider>
    );
  }

  private renderUnauthorized() {
    return (
      <>
        <InboxContext.Consumer>
          {(inboxCx) => (
            <NotificationListener
              cookiesCx={this.props.cookiesCx}
              inboxCx={inboxCx}
            />
          )}
        </InboxContext.Consumer>
        <Login authCx={this.props.authCx} />
      </>
    );
  }

  private renderMain(listCx: IPitchListsContext, machineCx: IMachineContext) {
    return (
      <SectionsContext.Consumer>
        {(sectionsCx) => (
          <Flex
            className="Main"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {/* sidebar */}
            <Box className="MainSidebar">
              <Sidebar
                cookiesCx={this.props.cookiesCx}
                authCx={this.props.authCx}
                machineCx={machineCx}
                sectionsCx={sectionsCx}
              />
            </Box>
            {/* content */}
            <Box className="MainContent" flexGrow="1">
              <Content
                globalCx={this.props.globalCx}
                cookiesCx={this.props.cookiesCx}
                authCx={this.props.authCx}
                machineCx={machineCx}
                sectionsCx={sectionsCx}
                listCx={listCx}
              />
            </Box>
          </Flex>
        )}
      </SectionsContext.Consumer>
    );
  }

  private provideMachine(
    hittersCx: IHittersContext,
    sessionsCx: ISessionEventsContext
  ): React.ReactNode {
    return (
      <MachineProvider
        cookiesCx={this.props.cookiesCx}
        authCx={this.props.authCx}
        sessionsCx={sessionsCx}
        hittersCx={hittersCx}
      >
        <MachineContext.Consumer>
          {(machineCx) => this.provideSections(machineCx)}
        </MachineContext.Consumer>
      </MachineProvider>
    );
  }

  private providePitch(
    listCx: IPitchListsContext,
    machineCx: IMachineContext
  ): React.ReactNode {
    return (
      <PitchDesignProvider machineCx={machineCx} listsCx={listCx}>
        {this.renderMain(listCx, machineCx)}
        <QueueListener machineCx={machineCx} />
        <MachineResponseListener machineCx={machineCx} />
      </PitchDesignProvider>
    );
  }

  private providePitchLists(
    authCx: IAuthContext,
    machineCx: IMachineContext,
    sectionsCx: ISectionsContext
  ): React.ReactNode {
    return (
      <MatchingShotsProvider
        authCx={authCx}
        sectionsCx={sectionsCx}
        machineCx={machineCx}
      >
        <MatchingShotsContext.Consumer>
          {(matchingCx) => (
            <PitchListsProvider
              authCx={this.props.authCx}
              machineCx={machineCx}
              matchingCx={matchingCx}
              sectionsCx={sectionsCx}
            >
              <PitchListsContext.Consumer>
                {(listCx) => this.providePitch(listCx, machineCx)}
              </PitchListsContext.Consumer>
            </PitchListsProvider>
          )}
        </MatchingShotsContext.Consumer>
      </MatchingShotsProvider>
    );
  }

  private provideSections(machineCx: IMachineContext) {
    return (
      <SectionsProvider
        authCx={this.props.authCx}
        cookiesCx={this.props.cookiesCx}
        machineCx={machineCx}
      >
        <SectionsContext.Consumer>
          {(sectionsCx) =>
            this.providePitchLists(this.props.authCx, machineCx, sectionsCx)
          }
        </SectionsContext.Consumer>
      </SectionsProvider>
    );
  }

  render() {
    if (env.maintenance) {
      return <Maintenance />;
    }

    const content = this.props.authCx.current.auth
      ? this.renderAuthorized()
      : this.renderUnauthorized();

    return (
      <ErrorBoundary componentName="Main" hideIntercom>
        {content}
      </ErrorBoundary>
    );
  }
}
