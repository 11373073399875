import { Flex, Heading } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachinePositionView } from 'components/machine/position-view';
import { ToastsDemo } from 'components/sections/feature-demo/toasts';
import { SectionHeader } from 'components/sections/header';
import { ICookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { CalloutsDemo } from './callouts';
import { ClientMessagesDemo } from './client-messages';
import { CustomIconsDemo } from './custom-icons';
import { MachineMessagesDemo } from './machine-messages';
import { ProcessesDemo } from './processes';
import { SectionBox } from './section-box';

interface IProps {
  cookiesCx: ICookiesContext;
}

interface IState {}

const DEFAULT_STATE: IState = {};

export class FeatureDemo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;
  }

  render() {
    return (
      <ErrorBoundary componentName="FeatureDemo">
        <MachineContext.Consumer>
          {(machineCx) => (
            <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
              <SectionHeader header={t('main.feature-demo')} />

              <SectionBox>
                <Heading size={RADIX.HEADING.SIZE.MD}>Machine View</Heading>

                <MachinePositionView
                  machineCx={machineCx}
                  onUpdate={(pos) => {
                    console.info(
                      `FeatureDemo: received machine position (${pos.px}, ${pos.pz})`
                    );
                  }}
                />
              </SectionBox>

              <MachineMessagesDemo />

              <ClientMessagesDemo machineCx={machineCx} />

              <ProcessesDemo />

              <CalloutsDemo />

              <ToastsDemo />

              <CustomIconsDemo />
            </Flex>
          )}
        </MachineContext.Consumer>
      </ErrorBoundary>
    );
  }
}
