import { Slider } from '@radix-ui/themes';
import { ISliderInput } from 'interfaces/forms/slider';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { CommonInputHint } from './hint';
import { CommonInputLabel } from './label';
import { CommonInputWrapper } from './wrapper';

export const CommonSliderInput = (props: ISliderInput) => (
  <CommonInputWrapper {...props}>
    <CommonInputLabel {...props} />

    <Slider
      color={RADIX.COLOR.NEUTRAL}
      min={props.min}
      max={props.max}
      step={props.step}
      value={[props.value]}
      onValueChange={(v) => {
        props.onChange?.(v[0]);
      }}
    />

    <CommonInputHint {...props} />
  </CommonInputWrapper>
);
