import {
  Box,
  Button,
  Flex,
  Heading,
  Separator,
  Skeleton,
  Spinner,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { CopyPitchesDialog } from 'components/common/dialogs/copy-pitches';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { PresetTrainingDialog } from 'components/machine/dialogs/preset-training';
import { TrainingDialog } from 'components/machine/dialogs/training';
import { SectionHeader } from 'components/sections/header';
import { BallFlightDesigner } from 'components/sections/pitch-design/ball-flight-designer';
import { PitchDesignSidebar } from 'components/sections/pitch-design/sidebar';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { PitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { DirtyForm, ISectionsContext } from 'contexts/sections.context';
import { TrainingContext, TrainingProvider } from 'contexts/training.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import {
  getMSFromMSDict,
  getMachineActiveModelID,
  getMergedMSDict,
} from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import {
  DEFAULT_PLATE,
  IBuildPitchChars,
  IPitch,
} from 'lib_ts/interfaces/pitches';
import React from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { PitchesService } from 'services/pitches.service';
import { SessionEventsService } from 'services/session-events.service';
import { StateTransformService } from 'services/state-transform.service';
import { v4 } from 'uuid';
import { MainForm } from './main-form';
import { SeamOrientation } from './seam-orientation';

const COMPONENT_NAME = 'PitchDesign';

const PITCH_BUILDING_WARNING_MSG = `Please wait for your pitch to finish building before trying again.`;

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  designCx: IPitchDesignContext;
  sectionsCx: ISectionsContext;
}

interface IDialogs {
  dialogSave?: number;
  dialogTraining?: number;
}

interface IState extends IDialogs {
  // changes over the life of this view
  workingChars?: Partial<IBuildPitchChars>;

  // does not change once set in constructor
  refChars?: Partial<IBuildPitchChars>;

  selectedPitches: Partial<IPitch>[];
}

export class PitchDesign extends React.Component<IProps, IState> {
  private init = false;
  private validInputsTimeout: any;
  private sidebar?: PitchDesignSidebar;
  private buildQueued = false;

  constructor(props: IProps) {
    super(props);

    const refPitch = props.designCx.reference;

    const refMS = refPitch?.msDict
      ? getMSFromMSDict(refPitch, props.machineCx.machine).ms
      : undefined;

    // we do the following twice to avoid mutation
    const refChars =
      refPitch && refMS
        ? {
            bs: refPitch.bs,
            ms: refMS,
            traj: refPitch.traj,
            plate: TrajHelper.getPlateLoc(refPitch.traj),
            seams: refPitch.seams,
            breaks: refPitch.breaks,
          }
        : undefined;

    const workingChars =
      refPitch && refMS
        ? {
            bs: refPitch.bs,
            ms: refMS,
            traj: refPitch.traj,
            plate: TrajHelper.getPlateLoc(refPitch.traj),
            seams: refPitch.seams,
            breaks: refPitch.breaks,
          }
        : undefined;

    const initialState: IState = {
      workingChars: workingChars,
      refChars: refChars,

      selectedPitches: [],
    };

    this.state = {
      ...initialState,
    };

    this.canBuildPitch = this.canBuildPitch.bind(this);
    this.getBuildPayload = this.getBuildPayload.bind(this);
    this.getBuildPriority = this.getBuildPriority.bind(this);
    this.getPitchPayload = this.getPitchPayload.bind(this);
    this.handleAddPitch = this.handleAddPitch.bind(this);
    this.handleUpdatePitch = this.handleUpdatePitch.bind(this);
    this.handleTrainPitch = this.handleTrainPitch.bind(this);
    this.loadPitchChars = this.loadPitchChars.bind(this);
    this.setBall = this.setBall.bind(this);
    this.updateRelease = this.updateRelease.bind(this);

    this.renderBody = this.renderBody.bind(this);
    this.renderSidebar = this.renderSidebar.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.renderSaveDialog = this.renderSaveDialog.bind(this);
    this.renderTrainingDialog = this.renderTrainingDialog.bind(this);
  }

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;

    if (!this.state.workingChars) {
      this.loadPitchChars(10);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.validInputsTimeout);
  }

  /** rebuilds pitchChars from bs (derived from ball + plate) */
  private loadPitchChars(remainingAttempts: number) {
    if (!this.canBuildPitch()) {
      if (remainingAttempts === 0) {
        return;
      }

      setTimeout(() => {
        /** try again shortly */
        this.loadPitchChars(remainingAttempts - 1);
      }, 500);
      return;
    }

    // pitch can be built
    const payload = this.getBuildPayload();
    if (!payload) {
      return;
    }

    StateTransformService.getInstance()
      .buildPitches({
        machine: this.props.machineCx.machine,
        notifyError: false,
        pitches: [payload],
      })
      .then((chars) => {
        if (chars && chars.length > 0) {
          this.setState({ workingChars: chars[0] });
          return;
        }

        if (remainingAttempts > 0) {
          /** try again after a brief pause... */
          setTimeout(() => {
            this.loadPitchChars(remainingAttempts - 1);
          }, 2_000);
          return;
        }

        /** notify the user about the error */
        NotifyHelper.warning({
          message_md: `There was a problem building your pitch. ${ERROR_MSGS.CONTACT_SUPPORT}`,
          buttons: [
            {
              label: 'Help Center',
              onClick: () => window.open(HELP_URLS.INTERCOM_LANDING),
            },
          ],
          inbox: true,
        });
      });
  }

  /** also tells sectionsCx of unsaved changes, dequeues before rebuilding chars */
  private setBall(value: Partial<IBallChar>) {
    if (!this.props.designCx.ball) {
      console.error('no ball');
      return;
    }

    // update the state immediately so the input shows the change
    this.props.designCx.setBall({
      ...this.props.designCx.ball,
      ...value,
    });

    this.props.sectionsCx.markDirtyForm(DirtyForm.PitchDesign);

    this.buildQueued = true;

    // we only want a single error check + rebuild based on the most recent value, after a brief pause
    clearTimeout(this.validInputsTimeout);

    this.validInputsTimeout = setTimeout(async () => {
      if (!this.props.designCx.ball) {
        console.error('no ball');
        return;
      }

      const warnings = PitchDesignHelper.getBallErrors(
        this.props.designCx.ball,
        this.props.cookiesCx.app.build_priority
      );

      // show an error toast if necessary
      if (warnings.length > 0) {
        this.buildQueued = false;

        NotifyHelper.warning({
          message_md: warnings[0],
        });
        return;
      }

      // rebuild the ball (e.g. so that traj view updates)
      const payload = this.getBuildPayload();
      if (!payload) {
        return;
      }

      const chars = await StateTransformService.getInstance()
        .buildPitches({
          machine: this.props.machineCx.machine,
          notifyError: true,
          pitches: [payload],
        })
        .then((results) => results[0])
        .catch((buildErr) => {
          console.error(buildErr);
        })
        .finally(() => {
          this.buildQueued = false;
        });

      if (!chars) {
        NotifyHelper.warning({
          message_md: 'Empty results received from pitch build.',
        });
        return;
      }

      const nextBall: IBallChar = { ...this.props.designCx.ball };

      switch (this.props.cookiesCx.app.build_priority) {
        case BuildPriority.Breaks: {
          if (chars.bs) {
            nextBall.wx = chars.bs.wx;
            nextBall.wy = chars.bs.wy;
            nextBall.wz = chars.bs.wz;

            const spinExt = BallHelper.convertSpinToSpinExt(nextBall);
            nextBall.wnet = spinExt.wnet;
            nextBall.gyro_angle = spinExt.gyro_angle;
            nextBall.waxis = spinExt.waxis;
          }
          break;
        }

        case BuildPriority.Spins:
        default: {
          if (chars.breaks) {
            nextBall.breaks = chars.breaks;
          }
          break;
        }
      }

      this.props.designCx.setBall(nextBall);

      this.setState(
        { workingChars: chars },
        () => this.sidebar?.restartAnimation('rebuild ball')
      );
    }, 1_000);
  }

  // for changing release position, does not trigger a rebuild via python
  private updateRelease(value: { px: number; pz: number }) {
    if (!this.props.designCx.ball) {
      console.error('no ball');
      return;
    }

    const nextBall: IBallChar = {
      ...this.props.designCx.ball,
      px: value.px,
      pz: value.pz,
    };

    this.props.sectionsCx.markDirtyForm(DirtyForm.PitchDesign);

    this.props.designCx.setBall(nextBall);

    const warnings = PitchDesignHelper.getBallErrors(
      nextBall,
      this.props.cookiesCx.app.build_priority
    );

    // show an error toast if necessary
    if (warnings.length > 0) {
      NotifyHelper.warning({
        message_md: warnings[0],
      });
      return;
    }

    // merge the new value with existing value without triggering rebuild (e.g. for release position change)
    const chars: Partial<IBuildPitchChars> = {
      ...this.state.workingChars,
    };

    if (chars.bs && chars.traj && chars.ms && chars.plate) {
      chars.bs.px = value.px;
      chars.bs.pz = value.pz;

      chars.traj.px = value.px;
      chars.traj.pz = value.pz;

      const aimed = AimingHelper.aimWithoutShots({
        chars: {
          bs: chars.bs,
          ms: chars.ms,
          traj: chars.traj,

          seams: chars.seams,
          breaks: chars.breaks,
          priority: this.getBuildPriority(),
        },
        release: {
          px: chars.bs.px,
          pz: chars.bs.pz,
        },
        plate_location: chars.plate,
      });

      this.setState(
        {
          workingChars: aimed,
        },
        () => this.sidebar?.restartAnimation('skip rebuild ball')
      );
    }
  }

  /** checks for errors, notifies if any, returns true iff there are no errors */
  private canBuildPitch(): boolean {
    if (!this.props.authCx.current.auth) {
      console.error('not authorized');
      return false;
    }

    if (!getMachineActiveModelID(this.props.machineCx.machine)) {
      console.error('no active model ID');
      return false;
    }

    if (!this.props.designCx.ball) {
      console.error('no ball');
      return false;
    }

    const warnings = PitchDesignHelper.getBallErrors(
      this.props.designCx.ball,
      this.props.cookiesCx.app.build_priority
    );
    warnings.forEach((text) => console.warn(text));

    return warnings.length === 0;
  }

  private getBuildPriority(): BuildPriority {
    return this.props.cookiesCx.app.build_priority ?? BuildPriority.Spins;
  }

  private async handleTrainPitch() {
    if (this.buildQueued) {
      NotifyHelper.warning({
        message_md: PITCH_BUILDING_WARNING_MSG,
      });
      return;
    }

    const payload = this.getPitchPayload();
    if (!payload) {
      return;
    }

    this.setState({
      dialogTraining: Date.now(),
      selectedPitches: [payload],
    });
  }

  private getBuildPayload(): Partial<IBuildPitchChars> | undefined {
    const ball = this.props.designCx.ball;

    if (!ball) {
      console.error('no ball');
      return;
    }

    const prio = this.getBuildPriority();

    return {
      temp_index: 0,
      bs: BallHelper.getBallStateFromChars(ball),
      plate: this.state.workingChars?.plate ?? DEFAULT_PLATE,
      priority: prio,
      seams: {
        latitude_deg: ball.latitude_deg,
        longitude_deg: ball.longitude_deg,
      },
      breaks: prio === BuildPriority.Breaks ? ball.breaks : undefined,
    };
  }

  private getPitchPayload(): Partial<IPitch> | undefined {
    if (!this.props.designCx.reference) {
      NotifyHelper.debug(
        {
          message_md: 'No reference pitch specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    if (!this.props.designCx.ball) {
      NotifyHelper.debug(
        {
          message_md: 'No ball specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    const chars = this.state.workingChars;

    if (!chars) {
      NotifyHelper.debug(
        {
          message_md: 'No pitch chars specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    if (!chars.bs) {
      NotifyHelper.debug(
        {
          message_md: 'No ball state specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    if (!chars.ms) {
      NotifyHelper.debug(
        {
          message_md: 'No machine state specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    if (!chars.traj) {
      NotifyHelper.debug(
        {
          message_md: 'No trajectory specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    if (!chars.plate) {
      NotifyHelper.debug(
        {
          message_md: 'No plate specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    const prio = this.getBuildPriority();

    if (prio === BuildPriority.Breaks && !this.props.designCx.ball?.breaks) {
      NotifyHelper.debug(
        {
          message_md: 'No breaks specified while prioritizing breaks!',
        },
        this.props.cookiesCx
      );
      return;
    }

    const aimed = AimingHelper.aimWithoutShots({
      chars: {
        bs: chars.bs,
        ms: chars.ms,
        traj: chars.traj,

        seams: chars.seams,
        // breaks are not necessary for aiming
        // breaks: this.state.ball.breaks,
        priority: prio,
      },
      release: {
        px: chars.bs.px,
        pz: chars.bs.pz,
      },
      plate_location: chars.plate,
    });

    const output: Partial<IPitch> = {
      _id: this.props.designCx.reference._id || `temp-${v4()}`,
      name: this.props.designCx.reference.name ?? '',

      bs: aimed.bs,
      msDict: getMergedMSDict(this.props.machineCx.machine, [aimed.ms]),
      traj: aimed.traj,

      seams: chars.seams,
      priority: prio,
      breaks:
        prio === BuildPriority.Breaks
          ? this.props.designCx.ball.breaks
          : undefined,

      plate_loc_backup: aimed.plate,
    };

    /** append video if selected, else leave alone to avoid overwriting video with undefined */
    const videoID = this.sidebar?.getVideoID();

    if (videoID) {
      output.video_id = videoID;
    }

    return output;
  }

  private async handleAddPitch() {
    if (this.buildQueued) {
      NotifyHelper.warning({
        message_md: PITCH_BUILDING_WARNING_MSG,
      });
      return;
    }

    const payload = this.getPitchPayload();
    if (!payload) {
      return;
    }

    this.setState({
      dialogSave: Date.now(),
      selectedPitches: [payload],
    });
  }

  private async handleUpdatePitch() {
    if (!this.props.designCx.reference) {
      console.warn('no reference pitch');
      return;
    }
    if (!this.props.designCx.reference._id) {
      console.warn('no reference pitch _id');
      return;
    }

    if (!this.props.designCx.reference._parent_id) {
      console.warn('no reference pitch _parent_id');
      return;
    }

    if (this.buildQueued) {
      NotifyHelper.warning({
        message_md: PITCH_BUILDING_WARNING_MSG,
      });
      return;
    }

    const payload = this.getPitchPayload();
    if (!payload) {
      return;
    }

    // add a new pitch that looks like the existing one
    const result = await PitchListsService.getInstance().postPitchesToList({
      listID: this.props.designCx.reference._parent_id,
      data: [
        {
          // take everything from the original pitch
          ...this.props.designCx.reference,
          // override specific fields with payload results
          ...payload,
        },
      ],
    });

    if (!result.success) {
      NotifyHelper.warning({
        message_md: result.error ?? t('pd.error-building-pitch-for-updating'),
      });
      return;
    }

    /** clear warning */
    this.props.sectionsCx.clearDirtyForm(DirtyForm.PitchDesign);

    // mark the old pitch as deleted
    await PitchesService.getInstance().deletePitches([
      this.props.designCx.reference._id,
    ]);

    NotifyHelper.success({
      message_md: t('common.x-updated', {
        x: this.props.designCx.reference.name,
      }),
    });
  }

  private renderTrainingDialog() {
    if (!this.state.dialogTraining) {
      return;
    }

    if (!this.state.selectedPitches) {
      return;
    }

    if (this.state.selectedPitches.length === 0) {
      return;
    }

    const mode = this.props.authCx.effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      return (
        <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
          <TrainingContext.Consumer>
            {(trainingCx) => (
              <TrainingDialog
                key={this.state.dialogTraining}
                identifier="PD-TrainingDialog"
                machineCx={this.props.machineCx}
                trainingCx={trainingCx}
                pitches={this.state.selectedPitches}
                threshold={this.props.machineCx.machine.training_threshold}
                onClose={() => this.setState({ dialogTraining: undefined })}
              />
            )}
          </TrainingContext.Consumer>
        </TrainingProvider>
      );
    }

    return (
      <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
        <TrainingContext.Consumer>
          {(trainingCx) => (
            <PresetTrainingDialog
              key={this.state.dialogTraining}
              identifier="PD-PT-TrainingDialog"
              machineCx={this.props.machineCx}
              trainingCx={trainingCx}
              pitches={this.state.selectedPitches}
              onClose={() => this.setState({ dialogTraining: undefined })}
            />
          )}
        </TrainingContext.Consumer>
      </TrainingProvider>
    );
  }

  private renderSaveDialog() {
    if (!this.state.dialogSave) {
      return;
    }

    return (
      <PitchListsContext.Consumer>
        {(listsCx) => (
          <CopyPitchesDialog
            key={this.state.dialogSave}
            identifier="PitchDesignSavePitchDialog"
            authCx={this.props.authCx}
            listsCx={listsCx}
            pitches={this.state.selectedPitches}
            description={t('pd.msg-new-pitch-ready').toString()}
            onCreated={() => {
              this.props.sectionsCx.clearDirtyForm(DirtyForm.PitchDesign);

              SessionEventsService.postEvent({
                category: 'pitch',
                tags: 'designer',
                data: {
                  action: 'save',
                  count: 1,
                },
              });

              this.setState({ dialogSave: undefined });
            }}
            onClose={() => this.setState({ dialogSave: undefined })}
          />
        )}
      </PitchListsContext.Consumer>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="PitchDesign">
        <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
          <SectionHeader
            header={t('main.pitch-design')}
            badge={this.props.designCx.reference?.name}
          />

          <CommonContentWithSidebar
            left={this.renderBody()}
            right={this.renderSidebar()}
          />
        </Flex>

        {this.renderSaveDialog()}
        {this.renderTrainingDialog()}
      </ErrorBoundary>
    );
  }

  private renderSidebar() {
    return (
      <VideosContext.Consumer>
        {(videosCx) => (
          <PitchDesignSidebar
            ref={(elem) => (this.sidebar = elem as PitchDesignSidebar)}
            designCx={this.props.designCx}
            videosCx={videosCx}
            chars={this.state.workingChars}
          />
        )}
      </VideosContext.Consumer>
    );
  }

  private renderBody() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Heading size={RADIX.HEADING.SIZE.MD}>
          {t('pd.ball-release-characteristics')}
        </Heading>

        {this.props.designCx.ball ? (
          <MainForm
            defaultPriority={this.props.designCx.reference?.priority}
            ball={this.props.designCx.ball}
            setBall={this.setBall}
            showRef={!!this.props.sectionsCx.active.fragment}
          />
        ) : (
          <Skeleton />
        )}

        <Separator size="4" />

        {this.props.designCx.ball ? (
          <SeamOrientation
            ball={this.props.designCx.ball}
            setBall={this.setBall}
            showRef={!!this.props.sectionsCx.active.fragment}
          />
        ) : (
          <Skeleton />
        )}

        <Separator size="4" />

        <Heading size={RADIX.HEADING.SIZE.MD}>
          {t('pd.ball-flight-characteristics')}
        </Heading>

        {this.state.workingChars && (
          <BallFlightDesigner
            cookiesCx={this.props.cookiesCx}
            authCx={this.props.authCx}
            machineCx={this.props.machineCx}
            chars={this.state.workingChars}
            onUpdatePlate={(plate) => {
              if (!this.props.designCx.ball) {
                console.error('no ball');
                return;
              }

              if (!this.state.workingChars?.bs) {
                return;
              }

              if (!this.state.workingChars?.traj) {
                return;
              }

              if (!this.state.workingChars?.ms) {
                return;
              }

              const rotatedChars = AimingHelper.aimWithoutShots({
                chars: {
                  bs: this.state.workingChars.bs,
                  traj: this.state.workingChars.traj,
                  ms: this.state.workingChars.ms,
                  priority:
                    this.state.workingChars.priority ?? BuildPriority.Spins,
                },
                release: {
                  px: this.props.designCx.ball.px,
                  pz: this.props.designCx.ball.pz,
                },
                plate_location: plate,
              });

              this.setState(
                {
                  workingChars: rotatedChars,
                },
                () =>
                  this.sidebar?.restartAnimation(
                    `${COMPONENT_NAME} > update plate`
                  )
              );
            }}
            onUpdateRelease={(pos) =>
              this.updateRelease({ px: pos.px, pz: pos.pz })
            }
          />
        )}

        {!this.state.workingChars && <Spinner />}

        {this.renderFooter()}
      </Flex>
    );
  }

  private renderFooter() {
    const BTN_CLASS = 'text-titlecase';

    return (
      <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
        {this.props.machineCx.connected && (
          <Box>
            {this.props.machineCx.calibrated ? (
              <Button
                className={BTN_CLASS}
                color={RADIX.COLOR.TRAIN_PITCH}
                disabled={!this.props.matchingCx.readyToTrain()}
                onClick={() => this.handleTrainPitch()}
              >
                {t('common.train-pitch')}
              </Button>
            ) : (
              <MachineCalibrateButton className={BTN_CLASS} />
            )}
          </Box>
        )}

        {this.props.designCx.reference?._id && (
          <Box>
            <Button
              className={BTN_CLASS}
              color={RADIX.COLOR.SUCCESS}
              onClick={() => this.handleUpdatePitch()}
            >
              {t('common.update-pitch')}
            </Button>
          </Box>
        )}

        <Box>
          <Button className={BTN_CLASS} onClick={() => this.handleAddPitch()}>
            {t('pd.add-to-pitch-list')}
          </Button>
        </Box>
      </Flex>
    );
  }
}
